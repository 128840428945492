<template>
  <div class="box">
    <van-empty
      v-if="list.length == 0"
      class="custom-image"
      :image="noData"
      description="暂无测额产品"
    />
    <img src="@/img/match/plan-bg.png" class="bg" v-if="list.length > 0" />
    <div class="product-box" v-if="list.length > 0">
      <van-row class="list-title">
        <van-col span="9">推荐办理顺序</van-col>
        <van-col
          span="6"
          @click="sort(0)"
          :class="orderType === 0 ? 'blue' : ''"
          >产品预估额度<van-icon class="iconfont icon-jiangxu icont"
        /></van-col>
        <van-col
          span="9"
          @click="sort(1)"
          :class="orderType === 1 ? 'blue' : ''"
          >综合利率<van-icon class="iconfont icon-shengxu icont"
        /></van-col>
      </van-row>
      <van-row
        class="list"
        v-for="item in list"
        :key="item.name"
        @click="toInfo(item.productId)"
      >
        <van-col span="9">
          <div class="list-name">{{ item.name }}</div>
        </van-col>
        <van-col span="6"
          ><span class="red" style="font-weight: bold; font-size: 14rpx"
            >{{
              item.productState == 0 && item.quotaW > 10
                ? item.quotaW
                : "不足10"
            }}万</span
          >
        </van-col>
        <van-col span="9">
          <span class="red">{{ item.rate }}%</span>
        </van-col>
      </van-row>
    </div>
    <div class="time" v-if="list.length > 0">{{ list[0].gmtCreate }}</div>
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper" @click.stop>
        <div>
          <img src="@/img/match/vip-hint.png" class="vip" @click="toVip" />
          <img
            src="@/img/match/close.png"
            class="close"
            @click="show = false"
          />
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import url from "@/service/url-config";
export default {
  data() {
    return {
      loanPredictionId: this.$route.query.loanPredictionId || "", //'10380',
      companyId: "",
      active: 0,
      time: "",
      value: 3,
      list: [],
      noData: require("@/img/no-data.png"),
      orderType: "",
      show: false,
    };
  },
  created() {
    if (this.loanPredictionId) {
      this.getList(this.loanPredictionId);
    } else {
      this.getList2();
    }
    // this.getAfterAuth(this.loanPredictionId);
  },
  methods: {
    //获取匹配成功/未成功产品 productState:0成功:1失败
    getAfterAuth(loanPredictionId) {
      this.$http.get(url.afterAuth + loanPredictionId).then((res) => {
        if (res.code == 200) {
          this.list = res.data;
        }
      });
    },
    getList(loanPredictionId) {
      this.$http
        .get(
          url.getProductListAfterAuthV2 +
            `?loanPredictionId=${loanPredictionId}&orderType=${this.orderType}`
        )
        .then((res) => {
          if (res.code == 200) {
            this.list = res.data;
          }
        });
    },
    getList2() {
      this.$http
        .post(url.getSuccessProductListByCompanyId, { orderType: 0 })
        .then((res) => {
          if (res.code == 200) {
            this.list = res.data;
          }
        });
    },
    sort(val) {
      this.orderType = val;
      this.getList(this.loanPredictionId);
    },
    //失败原因
    cause(row) {
      this.$http
        .get(
          url.failureCause +
            `?loanPredictionId=${row.loanPredictionId}&productId=${row.productId}`
        )
        .then((res) => {
          let object = res.data;
          for (const key in object) {
            if (Object.hasOwnProperty.call(object, key)) {
              if (
                !object[key] ||
                key == "gmtCreate" ||
                key == "gmtModified" ||
                key == "loanPredictionId" ||
                key == "productId" ||
                key == "loanPredictionProductResultId" ||
                key == "userIdCreate" ||
                key == "userIdModified"
              ) {
                delete object[key];
              }
              if (object.other) {
                let otherArr = object.other.split(",").filter((item) => item); //去空/null ,undefined
                if (otherArr.length > 0) {
                  otherArr.forEach((item, index) => {
                    object["other" + index] = item;
                  });
                }
                delete object["other"];
              }
            }
          }
          let cause = "";
          Object.keys(object).forEach((key, index) => {
            cause = cause + (index + 1) + ". " + object[key] + "\n";
          });
        });
    },
    //vip

    toVip() {
      this.$router.push({
        path: "vip",
      });
    },
    //产品详情
    toInfo(id) {
      if (!id) {
        this.show = true;
        return;
      }
      this.$router.push({
        path: "productInfo",
        query: {
          id: id,
          type: "putong",
        },
      });
    },
    toMeasurement() {
      this.$router.push({
        path: "/measurement",
        replace: true,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background: #f4f5f9;
  padding-top: 42px;
  min-height: calc(100vh - 42px);
  .bg {
    width: 350px;
    height: 126px;
    display: block;
    margin: 0 auto;
    margin-top: 10px;
  }
  .time {
    color: #000000;
    font-size: 11px;
    text-align: center;
    margin-top: 10px;
  }

  .tabs /deep/ .van-sticky {
    height: 50px;
    background: #ffffff;
  }

  .product-box {
    width: 355px;
    margin: 0 auto;
    margin-top: 15px;
    border-radius: 5px;
    background: #ffffff;
  }

  .product {
    width: calc(355px - 20px);
    height: calc(140px - 34px);
    border-bottom: 1px solid #f3f3f3;
    padding: 17px 10px;
  }

  .list-title {
    height: 42px;
    color: #555555;
    font-size: 12px;
    line-height: 42px;
    text-align: center;
    border-bottom: 1px solid #ededed;
  }

  .list {
    height: 42px;
    color: #555555;
    font-size: 12px;
    line-height: 42px;
    text-align: center;
    border-bottom: 1px solid #ededed;
  }

  .list-name {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .red {
    color: #f55c00;
    font-weight: bold;
  }

  .blue {
    color: #1377ff;
  }
  .button-box {
    width: 100%;
    margin-top: 62px;
    padding-bottom: 30px;
    display: flex;
    justify-content: space-around;

    div {
      width: 142px;
      height: 42px;
      background: #1377ff;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
      text-align: center;
      line-height: 42px;
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;

    .vip {
      width: 269px;
      height: 268px;
      display: block;
    }
    .close {
      width: 28px;
      height: 28px;
      display: block;
      margin: 0 auto;
      margin-top: 10px;
    }
  }
}
</style>